import { useEffect } from 'react';
import { useRouter } from 'next/router';

const gtagPageView = (url: URL) => {
  const gaId = (window as any).gaId;
  if (!gaId) {
    return false;
  }
  (window as any).gtag('config', gaId, {
    page_path: url,
  });
  return true;
};

export const useGaPageViewRouter = () => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      gtagPageView(url);
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    router.events.on('hashChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
      router.events.off('hashChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};
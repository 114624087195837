import { NextPageContext } from 'next';
import dayjs, { Dayjs } from '../../common/libs/dayjs';

let gaUserId: string | undefined;

export const getGaUserId = () => {
  return gaUserId;
};

export const onGaNextInitialProps = (ctx: NextPageContext) => {
  const gauid = ctx.query.gauid?.toString();
  if (gauid) {
    gaUserId = gauid;
  }
};

// export const DAY_TEXTS = ['일', '월', '화', '수', '목', '금', '토'];

// export const getDayText = (day: 0 | 1 | 2 | 3 | 4 | 5 | 6) => DAY_TEXTS[day];

// 해당 날짜에 해당 되는 월들의 Dayjs를 반환해주는 함수
// 잘려진 이전 달, 이전 월의 날짜도 포함
export const getCalendarDates = (now: dayjs.ConfigType): Dayjs[] => {
  const start = dayjs(now).startOf('month');
  const end = dayjs(now).endOf('month');
  const endDate = dayjs(end).get('date');

  const columns = Array.from({ length: endDate }, (_, i) => dayjs(start).add(i, 'day').startOf('date'));

  return fillEmptyCalendarDates(columns, start, end);
};

// 달력의 빈 이전 달(..., 29, 30), 다음 달(1, 2, 3 ...)을 채워주는 함수
const fillEmptyCalendarDates = (columns: Dayjs[], start: dayjs.ConfigType, end: dayjs.ConfigType): Dayjs[] => {
  const startDay = dayjs(start).get('day');
  const startEmptyColumns = Array.from({ length: startDay }, (_, i) =>
    dayjs(start)
      .subtract(startDay - i, 'day')
      .startOf('date'),
  );

  const endDay = dayjs(end).get('day');
  const endEmptyColumns = Array.from({ length: 6 - endDay }, (_, i) =>
    dayjs(end)
      .add(i + 1, 'day')
      .startOf('date'),
  );

  return [...startEmptyColumns, ...columns, ...endEmptyColumns];
};

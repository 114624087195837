export function isEmpty(value: any){
  if (!value) {
    return true;
  }
  if (value.length === 0) {
    return true;
  }
  if (typeof value == "object" && Object.keys(value).length === 0) {
    return true;
  }
  return false;
}
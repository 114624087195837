import { getGaUserId } from './utils';

type GoogleAnalyticsScriptProps = {
  gaId: string
}

export const GoogleAnalyticsScript = ({ gaId }: GoogleAnalyticsScriptProps) => {
  let html = `
                        window.gaId = '${gaId}';
                        window.dataLayer = window.dataLayer || [];
                        function gtag(){dataLayer.push(arguments);}
                        `;
  const gaUserId = getGaUserId();
  if (gaUserId) {
    html += `
                        gtag('set', '${gaId}', {
                          user_id: '${gaUserId}',
                        });
    `;
  }
  html += `
                        gtag('js', new Date());
                        gtag('config', '${gaId}', {
                          page_path: window.location.pathname,
                        });
                      `;

  return <>
    <script
      async
      src={`https://www.googletagmanager.com/gtag/js?id=${gaId}`}
    />
    <script
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  </>;
};